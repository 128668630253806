import "./src/styles/global.css"
// gatsby-browser.js
export const onInitialClientRender = () => {
  // Wait for Weglot to be loaded
  if (typeof window.Weglot !== "undefined") {
    window.Weglot.initialize({
      api_key: `${process.env.GATSBY_WEGLOT_API_KEY}`,
      original: "en",
    })
  } else {
    document.addEventListener("weglot-loaded", () => {
      window.Weglot.initialize({
        api_key: `${process.env.GATSBY_WEGLOT_API_KEY}`,
        original: "en",
      })
    })
  }
}

export const onRouteUpdate = () => {
  if (typeof window.Weglot !== "undefined") {
    window.Weglot.initialize({
      api_key: `${process.env.GATSBY_WEGLOT_API_KEY}`,
      original: "en",
    })
  } else {
    document.addEventListener("weglot-loaded", () => {
      window.Weglot.initialize({
        api_key: `${process.env.GATSBY_WEGLOT_API_KEY}`,
        original: "en",
      })
    })
  }
}
